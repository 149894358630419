import React from "react";
import { Container } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";

import PeopleImg_1 from "../images/people_img_1.png";
import PeopleImg_2 from "../images/people_img_2.png";
import PeopleImg_3 from "../images/people_img_3.png";
import PeopleImg_4 from "../images/people_img_4.png";
import PeopleImg_5 from "../images/people_img_5.png";
import PeopleImg_6 from "../images/people_img_6.png";
import PeopleImg_7 from "../images/people_img_7.png";
import PeopleImg_8 from "../images/people_img_8.png";

const InnerIntroModule = loadable(() => import("../components/InnerIntroModule/InnerIntroModule"));
const PeopleCard = loadable(() => import("../components/PeopleCard/PeopleCard"));
const StaticDetailPagination = loadable(() => import("../components/StaticDetailPagination/StaticDetailPagination"));

const PeopleLanding = (props) => {
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <div className="inner-wrapper">
                <Container>
                    <InnerIntroModule
                        title={"Our People"}
                    />

                    <div className="inner-wrapper-card-main people-wrapper">

                        <PeopleCard 
                            img={PeopleImg_1}
                        />

                        <PeopleCard 
                            img={PeopleImg_2}
                        />

                        <PeopleCard 
                            img={PeopleImg_3}
                        />

                        <PeopleCard 
                            img={PeopleImg_4}
                        />

                        <PeopleCard 
                            img={PeopleImg_5}
                        />

                        <PeopleCard 
                            img={PeopleImg_6}
                        />

                        <PeopleCard 
                            img={PeopleImg_7}
                        />

                        <PeopleCard 
                            img={PeopleImg_8}
                        />

                        <PeopleCard 
                            img={PeopleImg_1}
                        />

                        <PeopleCard 
                            img={PeopleImg_2}
                        />

                        <PeopleCard 
                            img={PeopleImg_3}
                        />

                        <PeopleCard 
                            img={PeopleImg_4}
                        />

                        <PeopleCard 
                            img={PeopleImg_5}
                        />

                        <PeopleCard 
                            img={PeopleImg_6}
                        />

                        <PeopleCard 
                            img={PeopleImg_7}
                        />

                        <PeopleCard 
                            img={PeopleImg_8}
                        />

                        <StaticDetailPagination 
                            tag="people-wrapper"
                        />
                    </div>
                </Container>
            </div>
        </Layout>
    )
}

export default PeopleLanding